<template>
  <ValidationObserver ref="notificationDetailsObserver">
    <DetailsForm>
      <template #actions>
        <div class="d-flex justify-content-between notification-details-card__footer mt-1">
          <div>
            <b-button variant="primary" >قبول</b-button>
            <b-button variant="outline-danger" class="mx-2"
              >رفض</b-button
            >
          </div>
          <b-button variant="outline-primary" to="/admin/notifications">تراجع</b-button>
        </div>
      </template>
    </DetailsForm>
  </ValidationObserver>
</template>
<script>
import DetailsForm from "../components/DetailsForm.vue";

export default {
  components: {
    DetailsForm,
  },
  props: {
    id: String,
  },
  computed: {},
  created() {},
  beforeDestroy() {},
  data: () => ({}),
  methods: {},
};
</script>

<style scoped lang="scss">
.notification-details-card {
  &__header {
    padding: 0rem !important;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
    }
  }

  &__title {
    font-size: 1.4rem;

    color: #5e5873;
  }

  &__notification-status-title {
    font-size: 1.1rem;

    color: #5e5873;
  }

  &__notification-status {
    background-color: rgba(0, 127, 255, 0.12) !important;
    font-size: 1rem;
    border-radius: 200px;
    font-weight: 700;
    padding: 0.4rem;
    width: 60px;
    &--isActive {
      color: #28c76f !important;
    }

    &--isExpired {
      color: #ff9f43 !important;
    }
  }

  &__footer-button {
    padding: 10px 25px;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.4px;
  }

  &__update-button {
    color: #ffffff !important;
  }

  &__remove-button {
    background-color: white !important;
    color: #ff0000 !important;
    border-color: #ff0000 !important;
  }

  &__back-button {
    color: #007fff !important;
    border-color: #007fff !important;
  }
  &__delete-button {
    background-color: #edf4f9 !important;
    color: #000000 !important;
  }
}
</style>
